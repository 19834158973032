@use "../../../../styles/variables.module" as *;
@use "../../../../styles/mixins";

$cellWidth: 40px;
$cellHeight: 40px;
$paddingX: 10px;
$paddingY: 5px;
$gutter: 20px;
$dayLabelWidth: 60px;
$box-shadow-blur-radius: 2px;
$internal-padding: 10px;
$eventsWidth: 500px + ($internal-padding * 2);
$calendarWidth: ($cellWidth * 7) + ($paddingX * 14) + ($internal-padding * 2);

$totalWidth: $eventsWidth + $calendarWidth;

.container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  column-gap: $gutter;
  flex-wrap: nowrap;
  width: 100%;

  @media(max-width: $break-point-md) {
    column-gap: $gutter*0.5;
  }
}

.contentContainer {
  text-align: center;
  flex-grow: 1;

  > span { // date
    font-size: 1.5rem;
    font-weight: 600;
  }

  table {
    td, th {
      padding: $paddingY $paddingX;
    }
    th {
      text-align: center;
      text-transform: uppercase;
      color: $black;
      font-size: 1rem;
    }
  }

  .contentTransition {
    transition: opacity .5s ease, transform .25s ease;
    opacity: 0;

    border: 1px solid #eaeaea;
    box-shadow: 2px 2px $box-shadow-blur-radius 0 #dcdcdc;
    border-radius: 10px;
    background: rgba(255, 255, 255, .5);
    padding: $internal-padding;
  }

  &:first-of-type {
    position: relative;

    .contentTransition {
      overflow-y: auto;
      transform: translateX(-20%);
      max-height: 600px;

      &HeightConstrained {
        @media(min-width: $break-point-md) {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
        max-height: 400px;
      }

      padding: $internal-padding;
      margin: 0;
    }
    @include mixins.translateTransition(translateX(-20%), translateX(0));

    ul {
      padding: 0;
    }
  }
  &:last-of-type {
    flex-grow: 0;
    width: $calendarWidth;

    .contentTransition {
      transform: translateX(20%);
      transition-delay: .5s;
      background: #f8f8f8;
    }
    @include mixins.translateTransition(translateX(20%), translateX(0));

    @media(max-width: $break-point-md) {
      display: none;
      flex-basis: 0;
    }
  }
  @include mixins.opacityTransition(0, 1);
}

.day {
  button {
    width: $cellWidth;
    height: $cellHeight;
    border-radius: 10px;
    border: 1px solid $lightgray;
    transition: none;
    font-weight: 700;
  }

  &Today button {
    border: 2px solid var(--blocks);
  }

  &Populated:not(.daySelected) button {
    background-color: #ececec;
  }

  &Selected button {
    background-color: var(--blocks) !important;
    color: var(--blocksTextColor);
  }
}

.controls {
  position: relative;
  margin: 5px;
  padding-top: 5px;
  min-height: 28px;
  font-size: 2rem;

  > span {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
  }

  button {
    top: 5px;
    position: absolute;

    &:first-of-type {
      left: 5px;
    }

    &:last-of-type {
      right: 5px;
    }
  }
}

.listControls {
  margin-bottom: 1rem;
}

$event-border-radius: 10px;
.dayEvent {
  border: 1px solid #eaeaea;
  border-radius: $event-border-radius;
  list-style: none;
  text-align: left;
  margin: 10px 0;
  position: relative;
  background: white;

  &:hover {
    .editButton {
      display: block;
    }
  }

  &Hidden {
    opacity: 0.5;
  }

  &:first-of-type {
    margin-top: 0;
  }

  > div {
    text-align: left;
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;

    & > span {
      display: block;

      &:first-of-type {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 1.5rem;
      }
    }
  }

  div {
    font-size: 1.1rem;
    span {
      margin-right: 5px;
    }
  }

  .dayLabel {
    width: $dayLabelWidth;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    text-align: center;
    background: #e8e8e8;
    border-top-left-radius: $event-border-radius;
    border-bottom-left-radius: $event-border-radius;
    font-weight: 600;
    text-transform: uppercase;
    transition: color .25s ease, background-color .25s ease;
  }

  &CurrentlySelectedDate .dayLabel {
    background: var(--blocks);
    color: var(--blocksTextColor);
  }

  .dayContent {
    max-width: 90%;
    padding: 10px;
    flex-basis: 50%;
    flex-grow: 1;

    > span {
      font-weight: 700;
      font-size: 1.3rem;
    }

    > div {
      padding-top: .5rem;
      font-size: 1rem;
      text-transform: uppercase;
    }
  }

  .location {
    flex-basis: 50%;
    padding: 10px;
    height: 80px;
    text-overflow: ellipsis;
    overflow: hidden;
    > span {
      display: inline-block;
      width: 100%;
      color: inherit;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
    }
  }
}

.editButton {
  position: absolute;
  right: 5px;
  bottom: 5px;
  display: none;
}

.eventInfo {
  width: calc(100% - $dayLabelWidth);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.eventTitle {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: $break-point-sm) {
  .eventInfo {
    flex-direction: column;
  }

  .dayEvent {

    .location {
      padding: 0px 10px 10px 10px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .eventTitle {
    max-width: 220px;
  }
}

.viewMoreButton {
  > div {
    margin: 0 !important;
    padding: 2rem 0 2rem 0;
    > button {
      max-width: 100px;
    }
  }
}
