@use "../../../styles/variables.module" as *;
@use "../../../styles/mixins";

.loginButtons {
  background: white;
  margin: 0 auto;
}

.newsFeedContainer {
  position: relative;
}

.displayContainer {
  margin: 0 $terrace-outer-margin 5rem $terrace-outer-margin;

  &Standalone {
    display: flex;
    column-gap: 40px;
    row-gap: 40px;
    align-items: flex-start;
    flex-direction: row;

    > *:first-of-type {
      flex-basis: 70%;
      flex-grow: 1;
    }
    .leaderboard {
      flex-basis: 30%;
      flex-shrink: 0;
    }

    @media(max-width: $break-point-md) {
      flex-wrap: wrap-reverse;
      margin-top: 30px;

      &[data-current-view="leaderboard"] {
        .leaderboard {
          flex-basis: 100%;
          display: block;
        }
      }

      > *:first-of-type {
        flex-basis: 100%;
      }
      .leaderboard {
        display: none;
      }
    }
  }
}

.feedContainer {
  display: grid;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;
  border-radius: 30px;
  overflow: hidden;
  transition: 0.5s ease-in-out;
}
.transitionWrapper {
  min-width: 0;
  @include mixins.opacityTransition(0, 1);
}

