@use "../../../../../styles/variables.module" as *;

.bottomUserDetails {
  text-align: center;
  color: $background-color;
  padding-top: 20px;

  h2 {
    padding-top: 10px;
    font-weight: 700;
    padding-bottom: 20px;
    font-size: 2rem;
  }

  .userImage {
    border-radius: 50%;
    object-fit: contain;
    width: 50px;
    height: 50px;
    margin: 0 auto;
    color: $theme-color-blue;

    &Placeholder {
      background-color: lighten($theme-color-blue, 70%);
      line-height: 50px;
      font-size: 1.4rem;
    }
  }

  .roundIcon {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    color: white;
    background-color: $admin-blue-light;
    line-height: 50px;
    font-size: 3rem;
    text-align: center;
    padding: 10px;
  }
}
