@use "../../../../../styles/variables.module" as *;
@use "SidebarPage.module";

.noPadding {
  padding: 0 !important;
}

.navigation {
  padding: 0;

  li {
    font-size: 1.5rem;
    color: $black;

    button, a {
      display: block;
      width: 100%;
      padding: 1.5rem;
      text-align: left;
    }

    span {
      font-size: 3rem;
      line-height: 3rem;
      vertical-align: middle;
      margin-right: 10px;
      color: $black;
    }

    a, span {
      color: $black;

      &:active {
        color: $black;
      }
    }

    &:nth-of-type(1) {
      background-color: lighten($admin-green-dark, 5%);
      //color: $admin-blue-dark;
    }
    &:nth-of-type(2) {
      background-color: lighten($admin-green-dark, 10%);
      //color: darken($admin-blue-dark, 10%);
    }
    &:nth-of-type(3) {
      background-color: lighten($admin-green-dark, 15%);
      //color: darken($admin-blue-dark, 20%);
    }
    &:nth-of-type(4) {
      background-color: lighten($admin-green-dark, 20%);
      //color: darken($admin-blue-dark, 30%);
    }
    &:nth-of-type(5) {
      background-color: lighten($admin-green-dark, 25%);
      //color: darken($admin-blue-dark, 40%);
    }
    &:nth-of-type(6) {
      background-color: lighten($admin-green-dark, 30%);
      //color: darken($admin-blue-dark, 40%);
    }
    &:nth-of-type(7) {
      background-color: $admin-green-dark;
      //color: darken($admin-blue-dark, 40%);
    }
  }
}
.editorSettings {
 ul {
   list-style: none;
 }
}
.tag {
  font-size: 1.5rem !important;
  background: $admin-blue-dark;
  color: white;
  padding: .15rem .25rem;
  border-radius: 5px;
}